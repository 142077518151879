.destination-input {
  position: relative;
  display: inline-block;
  border: 1px solid rgb(176, 176, 176) !important;
  width: 100%;
  border-radius: 12px;
  padding: 26px 37px 10px 10px !important;
  background: transparent;
  outline: none !important;
  font-size: 18px;
  font-weight: 600;
  color: #37ab31 !important;
  z-index: 1;
}
.destination-input::placeholder {
  font-weight: 400;
}
.share-input-container {
  display: flex;
  align-items: center;
}
.share-input-container svg {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  right: 20px;
}
.share-input {
  position: relative;
  width: 100%;
  padding: 26px 46px 10px 12px !important;
  background: transparent;
  outline: none !important;
  font-size: 18px;
  font-weight: 600;
  color: #37ab31 !important;
  z-index: 1;
  border: 1px solid rgb(176, 176, 176) !important;
  border-radius: 12px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.share-input::placeholder {
  font-weight: 400;
}
.destination-icon {
  position: absolute;
  top: 24px;
  right: 20px;
  font-size: 20px;
}
.input-labels {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.generate-button {
  width: 100%;
  padding: 14px 24px;
  transition: all 0.3s;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  --mouse-x: 50;
  --mouse-y: 50;
  background-size: 200% 200%;
  background-position: calc((100 - var(--mouse-x, 0)) * 1%) calc((100 - var(--mouse-y, 0)) * 1%);
  background-image: radial-gradient(circle, rgb(86, 219, 79) 0%, rgba(55, 171, 49, 1) 100%);
  cursor: pointer;
  position: relative;
  z-index: 1;
  text-align: center;
}
.generate-button.disabled {
  background-image: radial-gradient(circle, rgba(86, 219, 79, 0.7) 0%, rgba(55, 171, 49, 0.7) 100%);
  cursor: default;
}
.generate-icon {
  position: absolute;
  top: 16px;
  right: 20px;
  font-size: 20px;
  color: #fff;
  z-index: 1;
}
/* .trip-inputs-container{
  overflow-x: hidden;
} */

.trip-inputs-container input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.4em;
  height: 1.4em;
  border-radius: 0.2em;
  margin-right: 0.5em;
  border: 1px solid rgb(176, 176, 176) !important;
  outline: none;
  cursor: pointer;
}

.trip-inputs-container input[type="checkbox"].checked {
  position: relative;
  border: 1px solid #a0d19e !important;
}
.trip-inputs-container input[type="checkbox"].checked::before {
  content: "\2714";
  font-size: 2em;
  color: #37ab31;
  position: absolute;
  right: -5px;
  top: -18px;
}
.trip-inputs-container input[type="checkbox"]:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.trip-inputs-container input[type="checkbox"]:disabled + span {
  color: #c0c0c0;
}

.trip-inputs-container input[type="checkbox"]:focus {
  box-shadow: 0 0 5px rgb(176, 176, 176);
}
.trip-inputs-container input[type="checkbox"].checked:focus {
  box-shadow: 0 0 5px #a0d19e;
}
.restaurant-checkbox {
  position: relative;
  margin-top: 24px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.type-container {
  display: grid !important;
  contain: var(--contentscroller_contain);
  contain-intrinsic-size: var(--contentscroller_contain-intrinsic-size);
  content-visibility: var(--contentscroller_content-visibility);
  grid-auto-flow: column;
  grid-auto-columns: var(
    --contentscroller_auto-columns,
    calc((100% - var(--contentscroller_gap, 16px) * (var(--contentscroller_visible-items, unset) - 1)) / var(--contentscroller_visible-items, unset))
  );
  gap: var(--contentscroller_gap, 16px);
  grid-template-rows: var(--contentscroller_rows, none);
  grid-template-areas: var(--contentscroller_areas, none);
  justify-content: flex-start;
  min-height: var(--contentscroller_min-height);
  height: var(--contentscroller_height);
  overflow: var(--contentscroller_overflow, auto hidden);
  overscroll-behavior-inline: contain;
  scrollbar-width: thin;
  scrollbar-color: #37ab31 #ddd;
}
.type-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 12px;
  border-radius: 5px;
  height: 100%;
  justify-content: center;
}
.type-card input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.type-label {
  cursor: pointer;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  -webkit-filter: brightness(0.9) grayscale(1) opacity(0.7);
  -moz-filter: brightness(0.9) grayscale(1) opacity(0.7);
  filter: brightness(0.8) grayscale(0) opacity(0.7);
}
.type-label div:first-of-type {
  /* flex: 0 0 70px; */
  width: 40px;
  height: 44px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.type-label .description {
  flex: 1;
  text-align: left;
}
.type-label span {
  position: relative;
  font-size: 14px;
  /* font-weight: 600; */
}
.type-card input:checked + .type-label {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.type-label:hover {
  color: #000;
  -webkit-filter: brightness(1) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1) grayscale(0.5) opacity(0.9);
  filter: brightness(1) grayscale(0.5) opacity(0.9);
}
.type-card input {
  display: none;
}
.type-card input:checked + .type-label .description span::after {
  opacity: 1;
  background-color: #000;
}
.type-label .description span::after {
  position: absolute;
  content: "";
  height: 2px;
  top: calc(100% + 4px);
  background-color: #dddddd;
  inset-inline: 0;
  transition: opacity 0.2s cubic-bezier(0.2, 0, 0, 1);
  opacity: 0;
}
.type-label:hover .description span::after {
  opacity: 1;
}

.type-container::-webkit-scrollbar {
  /* width: 10px; */
  height: 4px;
}

.type-container::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom right, #ddd 0%, #ddd 100%);
  border-radius: 20px;
}
.autocomplete-dropdown-container {
  background: rgb(255, 255, 255) !important;
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px !important;
  box-sizing: border-box !important;
  margin-bottom: 16px !important;
  padding: 16px 0 !important;
  position: absolute !important;
  text-align: left !important;
  width: 100% !important;
  z-index: 999 !important;
}
.autocomplete-dropdown-container:empty {
  display: none;
}
.generate-container {
  position: relative;
  margin-top: 10px;
  text-align: center;
}
.generate-subtitle {
  font-size: 14px;
  margin-top: 4px;
}

.multiple-places-text {
  background-color: #37ab31;
  font-size: 0.9rem;
  margin: 2px;
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
  min-width: max-content;
}

.destination-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #37ab31;
}

.destination-container::-webkit-scrollbar {
  /* width: 10px; */
  background: #d4d4d4;
  height: 8px;
}

.interest-chip {
  /* background: linear-gradient(#d4d4d4, rgb(255, 244, 244)); */
  background: #d4d4d4;
  font-size: 0.9rem;
  margin: 2px;
  color: black;
  padding: 4px 8px;
  border-radius: 10px;
  min-width: max-content;
  outline: none;
  border: none;
}

.interest-chip-selected {
  background: #37ab31;
  color: white;
}

.radio-input:checked {
  opacity: 1;
}

.suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
}
.suggestion-item--active {
  cursor: pointer;
  padding: 5px 10px;
  background: #edecec;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  border-radius: 50%;
  margin: 30px auto;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.radio-input {
  /* Hide the default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Set the width and height */
  width: 20px;
  height: 20px;
  /* Create a custom circle */
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  /* Add some transition for smooth effect */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Add a check mark */
.radio-input::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #37ab31; /* Color when checked */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s;
}

/* When the radio button is checked */
.radio-input:checked {
  border-color: #37ab31;
}

/* Show the check mark when the radio button is checked */
.radio-input:checked::after {
  opacity: 1;
}
.suggestion-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 25px;
}

@media (min-width: 576px) {
  .min-width-md {
    min-width: 280px;
    max-width: 280px;
  }
}
/* @media (max-width: 576px) {
  .generate-icon {
    right: 5px;
  }
} */
