.rdp {
    --rdp-cell-size: 35px !important;
    --rdp-primary-color: #37ab31 !important;
    --rdp-accent-color: #e7ffeb !important;
    --rdp-background-color: #e7ffeb !important;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #20921a !important;
    --rdp-background-color-dark: #027039 !important;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
    margin: 50px 0 0 0 !important;
    border-radius: 16px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
    display: inline-block !important;
    padding: 24px 32px 16px !important;
    background: white;
    position: relative;
    z-index: 2;
}
.rdp-day_selected, .rdp-day_selected:focus-visible {
    color: #212529 !important;
}
.rdp-day_range_start, .rdp-day_range_end {
    background-color: var(--rdp-primary-color) !important;
    color: #fff !important;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    border: 1px solid var(--rdp-primary-color) !important;
}
.rdp-labels {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 10px;
    font-weight: 600;
}
.rdp-input-container {
    position: relative;
    display: inline-block;
    border: 1px solid rgb(176, 176, 176) !important;
    width: 50%;
}
.rdp-input-container.from {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.rdp-input-container.to {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: none !important;
}
.rdp-input {
    /* padding: 26px 12px 10px !important; */
    padding: 26px 0px 10px 10px !important;
    border: none !important;
    background: transparent;
    outline: none !important;
    font-size: 14px;
}
.rdp-caption_label {
    color: var(--rdp-primary-color) !important;
}




/* Flight Picker css  */


.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected {
  background-color: #4CAF50 !important; 
  color: white !important;
}



.react-datepicker__day--selected:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__time-list-item--selected:hover {
  background-color: #45a049 !important; /* Change this to your desired color */
}

/* Override the color of the time picker */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list-item--selected {
  background-color: #4CAF50 !important; /* Change this to your desired color */
  color: white !important;
  border: none !important;

}

/* Override the color of the header */
.react-datepicker__header {
  background-color: #fff !important; /* Change this to your desired color */
  border: none !important;
  border-radius: 16px !important;

}

.react-datepicker__current-month,
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select {
  /* color: #4CAF50 !important;  */
}

/* Override the color of the navigation buttons */
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  /* border-color: #4CAF50 !important; Change this to your desired color */
}

.flightTimePicker{
    margin-top: 65px;
  }



 .react-datepicker-popper , .react-datepicker{
  z-index: 5;
  font: inherit !important;
}
.react-datepicker__navigation{
  top: unset !important  ;
  width: 90px !important;
}
.react-datepicker{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
  /* padding: 24px 32px 16px !important; */
  padding: 0px 10px 10px !important;
}


.react-datepicker__navigation{
    width: 100px !important;
}



.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: 105px !important;
  }

.react-datepicker{
    border: none !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background: #e7ffeb !important;
  color: black !important;
  outline: 1px solid #37ab31;
  border-radius: 16px;
}
.react-datepicker__day--today  , .react-datepicker__day--weekend{
  background:transparent !important;
  color: #000 !important;
}
.react-datepicker__day--today :hover , .react-datepicker__day--weekend :hover{
  background:transparent !important;
}

/* 
.react-datepicker , .react-datepicker__header  , .react-datepicker__time {
  background: #fff !important;

}






.react-datepicker__day--in-range , .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  border-radius:  1.3rem !important; 
background-color: #37ab31 !important;

}




.react-datepicker__day--keyboard-selected { 
  color:  white !important;
} */
