*{
  box-sizing: border-box;
  margin: 0 ;
  padding: 0;
}
.App {
  text-align: center;
  min-height: 100vh;
  background-color: #fff;
  flex: 1 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
}
.container {
  transition: all 0.4s;
}
@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1700px !important;
  }
}
.contact-us {
  color: #0d1a26;
  padding: 8px 23px;
  border: 1px solid #222222;
  display: inline-block;
  margin-bottom: 0;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;   
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  border-radius: 8px;
}
.contact-us:hover {
  background-color: #f7f7f7;
}
.LabelingStep.container {
  max-width: 1500px !important;
}
.App-logo {
  margin-top: 1rem;
  height: 10vmin;
  pointer-events: none;
}
.App-header {
  background-color: #232323;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid #3de7f9;
}
.App-header-try-it {
  background-color: #232323;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid #3de7f9;
}
.sticky-card {
  position: sticky;
  z-index: 9999;
  top: 40px;
}
.App-link {
  color: #61dafb;
}
.App-body{
  background-color: #f9f9f9;
  min-height: 75vh;
  justify-content: center;
  color: #000000;
}
.body-try-it{
  min-height: 85vh;
}
#editor-box{
  max-height: 50vh;
}
.bg-boxes {
  height: 100%;
  min-height: 240px;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  pointer-events: none;
}
.footer {
  width: 100%;
  color: rgb(34, 34, 34);
  min-height: 15vh;
  text-align: center;
  background-color: #F7F7F7;
  border-top: 1px solid #DDDDDD;
  font-size: 14px;
}
.footer .container {
  padding: 1.5em;
}
/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .App-logo {
    height: 15vmin;
    pointer-events: none;
  }
}
@media screen and (max-width: 1399px) {
  .feature-list {
    margin-top: 3rem !important;
  }
}
/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .App-logo {
    height: 20vmin;
    pointer-events: none;
  }
  .activity-btn{
    padding: 5px 8px !important;
  }

  
}
a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}
.btn-outline-primary {
  color: #2E4C80;
  background-color: rgba(197,221,245,0.15);
  border-color: #2E4C80;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #2E4C80;
}
.social-network {
  height: 30px;
  width: 30px;
}
.header {
  background: rgba(238, 244, 245, 0.7);
  padding-top: 20px;
  padding-bottom: 30px;
  transition: all 0.4s;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

}
.logo{
  text-align: center;
  font-size: 30px;
  height: 7vh;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.hero-section {
  margin-bottom: 4rem;
}

.text-left {
  text-align: left!important;
}

@media only screen and (max-width: 600px) {
  .text-left {
    text-align: center!important;
  }
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
*, *::after, *::before {
  box-sizing: border-box;
}
*, ::after, ::before {
  box-sizing: border-box;
}
section {
  display: block;
}
h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
} 
.text-one {
  color: #37ab31;
}
.hero-content p {
  line-height: 30px;
  color: #696969;
}
@media (min-width: 991px) {
  .hero-content h1, .hero-content h2 {
    font-size: 45px;
  }
}
.hero-content h1, .hero-content h2 {
  text-transform: capitalize;
}
.primary-btn-start-demo {
  padding: 15px 30px;
  background: #8e51e5;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
  display: inline-block;
  font-weight: 700;
}
.primary-btn{
  padding: 15px 40px;
  background: #8e51e5;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
  display: inline-block;
  font-weight: 600;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
}
.primary-btn:hover {
  color:white;
  box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.bg-light-gray {
  background: #DEEEFC;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
*, *::after, *::before {
  box-sizing: border-box;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
/* :root {
  --amplify-primary-color: #7d36e3;
  --amplify-primary-tint: #7d36e3;
  --amplify-primary-shade: #7d36e3;
  --amplify-background-color: #ffffff;
  --amplify-text-color: #06c1e2;
  --amplify-font-family: 'Barlow', sans-serif;

  --amplify-secondary-color: #7d36e3;
  --amplify-box-shadow: 0px;
  --amplify-tertiary-color: #ffffff;
  --amplify-secondary-contrast: #ffffff;
} */
#home {
  padding-bottom: 100px;
}

footer a {
  color: rgb(34, 34, 34);
}
footer a:hover {
  color: #37ab31;
}
footer hr {
  opacity: 1;
}
footer a.social {
    background-color: #000;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 4px 7px;
    transition: all 0.3s;
    margin-right: 4px;
}
footer a.social svg {
  color: #e7ffeb;
}
footer a.social:hover {
  background-color: #37ab31;
}
/* footer .links {
  border-right: 1px solid #fff;
} */
footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
footer ul li {
  margin-top: 10px;
}
footer ul li a {
  opacity: 0.75;
  transition: all 0.3s;
}
footer ul li a:hover {
  opacity: 1;
}
/*HubSpot Styles*/
.hs-input,
#engageEmail input[type="text"],
#engageEmail input[type="email"] {
	background: #f2f2f2;
	border: none;
	border-bottom: solid 3px #8e51e5;
    font-size: 16px;
    min-height: 47px;
    padding: 6px 16px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
	width: 100% !important;
}
fieldset.form-columns-2,
fieldset.form-columns-1 {
	width: 100% !important;
	max-width: 100% !important;
}
.hs_submit input[type="submit"],
#engageEmail input[type="submit"] {
    background-color: #8e51e5;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
	border: none;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.hs_submit input[type="submit"]:hover {
    transform: scale(1.1);
}
footer .hs_submit input[type="submit"] {
	margin-top: 10px;
}
footer .hs-error-msg {
	color: #fff;
}
footer .modal .hs-error-msg {
	color: #000;
}
.hs-form-booleancheckbox-display {
  display: flex;
  align-items: center;
}
.hs-form-booleancheckbox-display input[type="checkbox"] {
  width: auto !important;
	margin: 0 !important;
}
.hbspt-form label {
	margin-top: 20px;
}
footer .grecaptcha-badge { 
  visibility: hidden;
	height: 0 !important;
}
/**/
.homepage-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  margin-bottom: 24px;
  /* overflow-x: hidden; */
}
.auth-form-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.auth-form {
  height: 400px;
  width: 600px;
  padding-bottom: 1.5em !important;
  padding: 0;
  justify-content: flex-start;
}
.btn.auth-toggle-button {
  border-radius: initial;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid;
  background-color: #666;
  border-color: #666;
  padding: 8px 0;
}
.btn.auth-toggle-button.active {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.auth-toggle-button-container {
  background-color: #666;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.your-trip {
  /* height: 580px;
  overflow: auto; */
  justify-content: flex-start;
}
.secondary-card {
  padding: 0 24px;
}
.secondary-card a {
  color: #000;
  text-decoration: underline;
}
.secondary-card a:hover {
  color: #000;
}
.secondary-card a:active {
  color: rgb(59, 59, 59);
}

/*Buttons*/
.button {
  display: inline-block;
  border-radius: 5px;
  padding: 14px 47px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}
.button.button-light {
  background-color: #fff;
  color: #232323;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 11%);
}
.button.button-grey {
  background-color: #f4f4f4;
  color: #232323;
  font-weight: 500;
}
.button.button-grey.disabled {
  cursor: initial;
  color: #adadad;
}
.button.button-purple {
  background-color: #8e51e5;
  border-color: #8e51e5;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}
.button.button-purple:hover,
.button.button-purple:focus
 {
  background-color: #8e51e5;
  border-color: #8e51e5;
}
.button.button-purple:disabled {
  background-color: #a87ee3;
  border-color: #a87ee3;
}

.button.button-purple-outline {
  background-color: #ffffff;
  border-color: #8e51e5;
  color: #8e51e5;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.button.button-purple-outline:hover,
.button.button-purple-outline:focus
{
  background-color: #8e51e5;
  border-color: #8e51e5;
  color: #fff;

}
.button.button-purple-outline:disabled {
  background-color: #a87ee3;
  border-color: #a87ee3;
  color: #fff;

}

.button.button-light-purple {
  background-color: #f5f3ff;
  color: #8e51e5;
  font-weight: 500;
}
.button.button-edit {
  background-color: #f3f7ff;
  color: #5193e5;
  font-weight: 500;
}
.button.button-delete {
  background-color: #fff3f3;
  color: #e55151;
  font-weight: 500;
}
.button.button-cta {
  background-color: #f18f01;
  color: #fff;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 11%);
  font-weight: 500;
}
.button:hover {
  box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.button.button-grey.disabled:hover {
  box-shadow: none;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.auth-form form {
  padding: 0 40px;
}
.auth-form input {
	background: #f2f2f2;
	border: none;
	border-bottom: solid 3px #8e51e5;
  font-size: 16px;
  min-height: 47px;
  padding: 6px 16px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 15px;
	width: 100% !important;
}
.auth-form button[type="submit"] {
	background: #8e51e5;
	border-color: #8e51e5;
  padding: 10px 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.auth-form button[type="submit"]:hover {
  opacity: 0.7;
}
.result-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
  overflow: auto;
}
.result-map-container {
  position: relative;
  height: 100%;
}
.result-map {
  height: 900px;
  width: 100%;
  position: sticky;
  top: 39px;
  z-index: 99;
}
.result-map > div > div {
  border-radius: 12px;
}
.result-container  {
  margin: 0 !important;
}
.result-container > div:first-child, .result-container .col-xxl-7{
  padding-left: 0;
}
.result-container > div:last-child {
  padding-right: 0;
}
.result-container h2 {
  margin-top: 80px;
  margin-bottom: 40px;
}
.days-container {
  display: flex;
  justify-content: center;
  overflow: auto;
}
.days-container.down {
  margin-bottom: 0;
  margin-top: 0;
}
.days-container .day {
  margin: 4px 16px;
  border-right: none;
  cursor: pointer;
  color: #717171;
}
.days-container .day:first-child {
  border-right: none;
  margin-left: 0;
}
.days-container .day:last-child {
}
.days-container .day:hover {
  border-bottom: 2px solid rgb(176, 176, 176) !important;
  color: #000;
}
.days-container .day.selected {
  border-bottom: 2px solid #37ab31 !important;
  font-weight: 600;
  color: #000;
}
.activities-login-overlay {
  position: sticky;
  top: 39px;
  z-index: 999999;
  background: #fff;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 48px;
  box-shadow: rgba(0, 0, 0, 0.28);
  border-radius: 12px;
  padding: 24px;
  transition: all .3s;
}
.activities-login-overlay button {
  width: 100%;
}
.activities-container {
  position: relative;
}
.activities-cards-container.blurred {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  margin-top: -120px;
}
.activity-card.blurred {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  margin-top: -100px;
}
.activity-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #000;
  width: calc(100%);
  transition: transform .3s;
  border-radius: 12px;
  border: 1px solid #ddd;
  margin-bottom: 24px;
  /* max-height: 250px; */
}
.activity-content {
  flex: 1 0;
  position: relative;
  /* border-radius: 12px 0px 0px 12px;
  border-right: 0 !important; */
  border-radius: 12px;
  padding: 20px;
  min-height: 180px;
  /* border: 1px solid rgb(221, 221, 221) !important; */
  text-align: left;
}
.activity-content > span {
  display: block;
}
.activity-image img{
  width: 225px;
  position: relative;
  object-fit: cover;
  height: 100%;
  border-radius: 0px 12px 12px 0px;
}
.activity-title , .accordion-button {
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  margin-bottom: 2px;
}
.activity-rating {
  font-size: 0.85rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
  margin-bottom: 5px;
  position: relative;
  display: flex !important;
}
.activity-rating .stars {
  position: relative;
  width: 5.6em;
  overflow: hidden;
  height: 1.118em;
  margin-left: 5px;
}
.activity-rating .stars::before {
  -webkit-mask-image: url(data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.04893 2.92707C9.34828 2.00576 10.6517 2.00576 10.951 2.92707L12.0206 6.21886C12.1545 6.63089 12.5384 6.90985 12.9717 6.90985H16.4329C17.4016 6.90985 17.8044 8.14946 17.0207 8.71886L14.2205 10.7533C13.87 11.0079 13.7233 11.4593 13.8572 11.8713L14.9268 15.1631C15.2261 16.0844 14.1717 16.8506 13.3879 16.2812L10.5878 14.2467C10.2373 13.9921 9.76269 13.9921 9.4122 14.2467L6.61203 16.2812C5.82832 16.8506 4.77384 16.0844 5.07319 15.1631L6.14276 11.8713C6.27663 11.4593 6.12997 11.0079 5.77949 10.7533L2.97932 8.71886C2.1956 8.14946 2.59838 6.90985 3.5671 6.90985H7.0283C7.46153 6.90985 7.84548 6.63089 7.97936 6.21886L9.04893 2.92707Z' fill='%234B5563'/%3E%3C/svg%3E);
  -webkit-mask-position: left;
  -webkit-mask-repeat: repeat-x;
  -webkit-mask-size: 1.118em;
  background: #ccc;
  content: "";
  display: block;
  height: 1.118em;
  left: 0px;
  position: absolute;
  top: 3px;
  width: 5.6em;
}
.activity-rating .stars span {
  display: block;
  text-indent: -10000px;
  height: 1.118em;
  position: absolute;
  overflow: hidden;
}
.activity-rating .stars span::before {
  -webkit-mask-image: url(data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.04893 2.92707C9.34828 2.00576 10.6517 2.00576 10.951 2.92707L12.0206 6.21886C12.1545 6.63089 12.5384 6.90985 12.9717 6.90985H16.4329C17.4016 6.90985 17.8044 8.14946 17.0207 8.71886L14.2205 10.7533C13.87 11.0079 13.7233 11.4593 13.8572 11.8713L14.9268 15.1631C15.2261 16.0844 14.1717 16.8506 13.3879 16.2812L10.5878 14.2467C10.2373 13.9921 9.76269 13.9921 9.4122 14.2467L6.61203 16.2812C5.82832 16.8506 4.77384 16.0844 5.07319 15.1631L6.14276 11.8713C6.27663 11.4593 6.12997 11.0079 5.77949 10.7533L2.97932 8.71886C2.1956 8.14946 2.59838 6.90985 3.5671 6.90985H7.0283C7.46153 6.90985 7.84548 6.63089 7.97936 6.21886L9.04893 2.92707Z' fill='%234B5563'/%3E%3C/svg%3E);
  -webkit-mask-position: left;
  -webkit-mask-repeat: repeat-x;
  -webkit-mask-size: 1.118em;
  background: #fdd835;
  content: "";
  display: block;
  height: 1.118em;
  left: 0px;
  position: absolute;
  top: 3px;
  width: 5.6em;
}
.activity-description {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
  margin-bottom: 5px;
}
.activity-time {
  color:#717171;
  font-size: 0.85rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
}
.activity-address {
  color:#717171;
  font-size: 0.85rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
}
.placeholder-text {
  padding: 0 24px;
}

@media (max-width: 768px) {
  .result-container > div:first-child, .result-container .col-xxl-7 {
    padding-right: 0;
  }
  .activity-book {
    border-bottom: 0 !important;
  }
  .result-map {
    height: 400px;
  }
  .scroll-to-map {
    display: inline-block !important;
  }
  .tools-container {
    margin-bottom: 10px;
  }
  .result-container > div:last-child {
    padding-left: 0;
  }
  .result-map-container {
    margin-top: 40px;
  }
  .activity-card {
    /* flex-direction: column-reverse !important; */
    flex-direction: column !important;
    max-height: none !important;
  }
  .activity-book {
    margin-bottom: 16px;
  }
  .activity-image img{
    width: 100%;
    position: relative;
    object-fit: cover;
    max-height: 180px;
    border-radius: 12px 12px 0px 0px;
  }
  .activity-content {
    /* border-radius: 0px 0px 12px 12px;
    border-top: 0 !important; */
    min-height: auto !important;
  }
  .days-container {
    justify-content: flex-start;
  }
  .result-toolbar {
    flex-direction: column-reverse;
  }
}
.authenticator-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 350px;
  z-index: 9999999;
}
[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: rgba(0, 0, 0, 0.28) !important;
  border: none !important;
  border-radius: 12px;
}
.authenticator-modal .amplify-tabs {
  border-radius: 12px;
}
.amplify-tabs-item[data-state=active] {
  color: #37ab31 !important;
  border-color: #37ab31 !important;
}
.amplify-tabs-item:hover, .amplify-tabs-item:active {
  color: #37ab31 !important;
}
.authenticator-modal .amplify-tabs button:first-child {
  border-top-left-radius: 12px;
}
.authenticator-modal .amplify-tabs button:last-child {
  border-top-right-radius: 12px;
}
.amplify-button {
  border-radius: 8px !important;
}
.amplify-button.amplify-field__show-password {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.amplify-button:hover {
  --amplify-internal-button-background-color: rgb(233 252 235) !important;
  --amplify-internal-button-border-color: rgb(64 191 79) !important;
}
.amplify-button:focus {
  --amplify-internal-button-background-color: rgb(233 252 235) !important;
  --amplify-internal-button-border-color: rgb(0, 77, 4) !important;
  box-shadow: rgb(0, 77, 4) 0px 0px 0px 1px !important;
}
.amplify-button:active {
  --amplify-internal-button-background-color: rgb(188, 245, 188) !important;
  --amplify-internal-button-border-color: rgb(0, 77, 4) !important;
}
.amplify-button--link {
  --amplify-internal-button-color: #37ab31 !important;
}
.amplify-button--primary {
  --amplify-internal-button-background-color: #37ab31 !important;
  --amplify-internal-button-border-color: #37ab31 !important;
}
.amplify-button--primary:hover  {
  --amplify-internal-button-background-color: #1f8d19 !important;
  --amplify-internal-button-border-color: #1f8d19 !important;
}
.amplify-input {
  border-radius: 8px !important;
}
input[type=password].amplify-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.amplify-input:focus {
  border-color: rgb(0, 77, 4) !important;
  box-shadow: rgb(0, 77, 4) 0px 0px 0px 1px !important;
}
.auth-overlay {
  position: fixed;
  top: 0;
  background: rgb(34 34 34 / 15%);
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 400ms;
  animation-name: overlayEnter;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999998;
  overflow-y: auto;
}
.offcanvas-backdrop {
  z-index: 99997 !important;
}
.offcanvas {
  z-index: 99998 !important;
}
.dropdown-item.active, .dropdown-item:active {
  text-decoration: none;
  background-color: #37ab31 !important;
}
.feature-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 1rem);
  margin-top: 1rem;
}
.feature-list .feature p {
  margin: 0;
}
.feature-list .feature svg {
  font-size: 26px;
  margin-right: 8px;
}
#howitworks, #features {
  margin-top: 80px;
}
#howitworks h2, #features h2 {
  text-align: center;
  margin-bottom: 40px;
}
.works-card {
  display: flex;
  border-radius: 12px;
  padding: 24px;
  background-color: rgb(247, 247, 247);
  gap: 20px;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}
.works-num {
  width: 34px;
  text-align: center;
  background: #37ab31;
  padding: 4px;
  color: #fff;
  border-radius: 1000px;
  margin-bottom: 8px;
}
.works-txt {
  font-weight: 500;
  font-size: 18px;
}
.works-card img {
  margin: -24px;
}

@media (max-width: 600px) {
  .works-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .works-card {
    flex-direction: column;
  }
}
.features-card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
  background-color: rgb(235, 251, 236);
  gap: 10px;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}
.features-title {
  margin-top: 10px;
}
.features-card img {
  max-width: 100%; 
}
.modal-backdrop {
  z-index: 10500 !important;
}
.modal {
  z-index: 10501 !important;
}
.save-trip {
  color: #000;
  padding: 8px 20px;
  background-color: #e9fceb;
  border: 1px solid #37ab31;
  display: inline-block;
  margin-bottom: 0;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;   
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  border-radius: 8px;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
  cursor: pointer;
  margin-right: 4px;
}
.save-trip svg{
  margin-left: 4px;
}
.save-trip:hover {
  color: #fff;
  background-color: #37ab31;
}
.share-trip {
  color: #0d1a26;
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #222222;
  display: inline-block;
  margin-bottom: 0;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;   
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  border-radius: 8px;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}
.scroll-to-map {
  color: #0d1a26;
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #222222;
  display: none;
  margin-bottom: 0;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;   
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  border-radius: 8px;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
  cursor: pointer;
  margin-right: 4px;
}
.share-trip:hover, .scroll-to-map:hover, .react-select__control:hover {
  background-color: #f7f7f7 !important;
}
.modal-footer .btn-success {
  background-color: #37ab31 !important;
  border: 1px solid #37ab31 !important;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
}
.modal-footer .btn-secondary {
  background-color: #fff !important;
  border: 1px solid #0d1a26 !important;
  color: #000 !important;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
}
.modal-footer .btn-success:hover {
  background-color: #5bd154 !important;
}
.modal-footer .btn-secondary:hover {
  background-color: #F7F7F7 !important
}
.navbar-trip {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.navbar-trip svg {
  font-size: 13px;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
}
.navbar-trip svg:hover {
  color: #bb2d3b;
}
.share-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: space-evenly;
}
.generating-response {
  flex: 1 0;
  position: relative;
  border-radius: 12px;
  padding: 20px;
  min-height: 180px;
  border: 1px solid rgb(221, 221, 221) !important;
  text-align: left;
  height: calc(100% - 15px);
  max-height: 656px;
  overflow: auto;
}
.generating-response .notice {
  padding: 10px;
  background-color: rgb(235, 251, 236);
  border-radius: 8px;
  margin-bottom: 15px;
}
.open-on-maps-icon {
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 10px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 20px;
  width: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 999999999;
}
.distance-component {
  text-align: center;  
}
.select-mode {
  display: inline-block;
}
.react-select__control {
  color: #0d1a26;
  padding: 1px 2px;
  background-color: #fff;
  border: 1px solid #222222 !important;
  display: inline-block;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;   
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  border-radius: 8px !important;
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s;
  cursor: pointer;
  margin-right: 4px;
}
.activity-book {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;
  align-items: center;
  flex-wrap: wrap;
}
.activity-book .book {
  margin-top: 12px;
  margin-bottom: -1px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
.activity-book img {
  max-height: 20px;
  padding: 2px 6px;
  /* border: 1px solid rgb(221, 221, 221) !important; */
  border-radius: 4px;
}

.back-btn{
  cursor: pointer;
}
.activity-btn{
  font-weight: 500;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 25px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.buy-ticket-btn {
  background: #e9fceb;
  color: #37ab31;
}
.buy-ticket-btn:hover {
  background: #c2f6c0;
}
.google-map-btn {
  background: #f7f7f7;
  color: #454545;
}
.google-map-btn:hover {
  background: #dcdbdb;
}

.trip-add-input{ 
  padding: 8px 5px;
  border: 1px solid gray;
  border-radius: 5px;
}
.react-datepicker-wrapper{
  /* width:  100% !important; */
  display: unset !important;
}

.dropdown-toggle::after{
  display: none !important;
}